<template>
  <div  class="chat-screen-container">
  <div class="chat-screen ">
    <!-- Header shows the file name if uploaded -->
    <div class="header">{{
        selectedFile ? `Dosya: ${selectedFile.name}` : 'Sorgulama yapabilmek için dosya yüklemelisiniz'
      }}
    </div>

    <div class="chat-box" ref="chatBox">
      <!-- Loop through messages and align them based on sender -->
      <div
          v-for="(message, index) in chatMessages"
          :key="index"
          class="message-wrapper"
          :class="messageAlignment(message)"
      >
        <span class="message-label" v-if="message.sender === 'user'">You</span>
        <span class="message-label" v-else>API</span>
        <!-- Use v-html to display the message and replace new lines with <br> -->
        <div class="message-content" v-html="formatMessage(message.text)"></div>

        <!-- Display alternative questions if they exist -->
        <div v-if="message.alternatives" class="alternatives">
          <div
              v-for="(alternative, idx) in message.alternatives"
              :key="idx"
              class="alternative-item"
              @click="sendAlternativeQuestion(alternative)"
          >
            {{ alternative }}
          </div>
        </div>
      </div>

      <!-- Show file uploading message inside chat board -->
      <div v-if="isUploading" class="message-wrapper user-message">
        <span class="message-label">You</span>
        <div class="message-content">
          Uploading file...
          <div class="loader-animation"></div>
        </div>
      </div>

      <!-- Show loader and message while waiting for response inside chat board -->
      <div v-if="isSendingMessage" class="message-wrapper api-message">
        <span class="message-label">API</span>
        <div class="message-content">
          Waiting for response...
          <div class="loader-animation"></div>
        </div>
      </div>
    </div>

    <div class="input-box">
      <!-- PDF Icon for File Upload -->
      <label class="file-upload">
        <input type="file" @change="onFileChange" class="file-input"
               accept="application/pdf"/>
        <img src="@/assets/pdf-icon.png" alt="Upload PDF" class="pdf-icon"/>
      </label>
      <!-- Show file name in the header instead of status -->
      <input
          type="text"
          placeholder="Type your message"
          v-model="newMessage"
          @keyup.enter="sendMessage"
          :disabled="!selectedFile || isUploading"
          :class="{ 'disabled-input': !selectedFile || isUploading }"
          class="text-input"
      />
      <button
          @click="sendMessage"
          :disabled="!selectedFile || isUploading"
          :class="{ 'disabled-button': !selectedFile || isUploading }"
      >
        Send
      </button>
    </div>
  </div>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      chatMessages: [],
      newMessage: '',
      selectedFile: null,
      isUploading: false,  // Track if the file is being uploaded
      isSendingMessage: false,  // Track if we are waiting for a response
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() === '') return;

      // Add the user's message aligned to the right
      this.chatMessages.push({text: this.newMessage, sender: 'user'});
      this.isSendingMessage = true;  // Show waiting for response

      // Scroll to bottom after message is added
      this.$nextTick(() => {
        this.scrollToBottom();
      });

      // Send the message to the server
      axiosInstance.post('/chat', {message: this.newMessage})
          .then(response => {
            const backendResponse = response.data["response"];
            console.log('Message sent:', backendResponse);

// Extract alternative questions if they exist
            let alternatives = this.extractAlternatives(backendResponse);

// Check if the #ALTERNATIVE tag exists
            let messageText = backendResponse;
            const alternativeMarker = '#ALTERNATIVE';
            if (backendResponse.includes(alternativeMarker)) {
              // Show the message up to the #ALTERNATIVE marker
              messageText = backendResponse.split(alternativeMarker)[0].trim();
            }

// Add the API response aligned to the left, with or without alternatives
            this.chatMessages.push({
              text: messageText,
              sender: 'api',
              alternatives: alternatives
            });

            this.isSendingMessage = false;  // Hide waiting for response
            this.$nextTick(() => {
              this.scrollToBottom(); // Ensure scroll after API response
            });
          })
          .catch(error => {
            console.error('Error sending message:', error);
            this.isSendingMessage = false;
          });

      this.newMessage = '';
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      if (this.selectedFile) {
        this.uploadFile();
      }
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      this.isUploading = true;  // Show uploading message

      axiosInstance.post('/chat/pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(response => {
            console.log('File uploaded:', response.data);

            const responseData = response.data;
            // Add a file upload message aligned to the right
            this.chatMessages.push({
              text: `File uploaded: ${this.selectedFile.name}`,
              sender: 'user'
            });

            // Check if the response starts with "OCR_"
            if (responseData.includes("OCR_")) {
              // Add a message indicating OCR and AI usage
              this.chatMessages.push({
                text: "PDF dosyası görseller içerdiği için Optik Karakter Tanıma ve AI kullanılarak tarandı.<br/> İçeriğin doğruluğunu kontrol etmeniz gerekebilir.",
                sender: 'api'
              });
            }



            this.isUploading = false;  // Hide uploading message
            this.$nextTick(() => {
              this.scrollToBottom(); // Ensure scroll after file upload
            });
          })
          .catch(error => {
            console.error('Error uploading file:', error);
            this.isUploading = false;
          });
    },
    extractAlternatives(responseText) {
      // Check if response contains #ALTERNATIVE and extract the list
      const alternativeMarker = '#ALTERNATIVE';
      if (responseText.includes(alternativeMarker)) {
        const start = responseText.indexOf('{');
        const end = responseText.indexOf('}');
        if (start !== -1 && end !== -1) {
          const alternativeString = responseText.slice(start + 1, end);
          return alternativeString.split(',').map(alt => alt.trim().replace(/"/g, ''));
        }
      }
      return null;
    },
    sendAlternativeQuestion(question) {
      // Automatically send the clicked alternative question
      this.newMessage = question;
      this.sendMessage();
    },
    messageAlignment(message) {
      return message.sender === 'user' ? 'user-message' : 'api-message';
    },
    formatMessage(text) {
      // Replace newline characters with <br> for HTML display
      return text.replace(/\n/g, '<br>');
    },
    scrollToBottom() {
      const chatBox = this.$refs.chatBox;
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  },
  props: {
    msg: {
      type: String,
      default: 'Welcome to the chat!',
    },
  },
  mounted() {
    this.scrollToBottom(); // Ensure chat is scrolled to bottom when mounted
  },
};
</script>

<style scoped>

@import '@/views/chatscreen/ChatScreen.css';

</style>
