// src/stores/auth.js

import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    user: null,
  }),
  getters: {
    isUserAuthenticated(state) {
      // Check if the token exists and is valid
      return !!state.token;
    },
    isAdmin(state) {
      // Example: check if the user role is admin
      return state.user?.role === 'ROLE_ADMIN';
    },
    isUser(state) {
      // Example: check if the user role is a regular user
      return state.user?.role === 'ROLE_USER';
    },
  },
  actions: {
    login(role) {
      this.user = { role };
      this.token = localStorage.getItem('access_token');
    },
    logout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    retrieveSession() {
      const token = localStorage.getItem('access_token');
      if (token) {
        this.token = token;
        // Optional: Add logic to parse and validate the token here if needed
      }
    },
  },
});
